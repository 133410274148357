<template>
  <CSidebar
    :show="$store.state.asideShow"
    @update:show="(val) => $store.commit('set', ['asideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
    class="c-sidebar-left"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'asideShow')" />
    <CTabs tabs class="nav-underline nav-underline-primary">
      <CTab active>
        <template slot="title">
          <CIcon name="cil-list" /> {{ $t("aside.roles.title") }}
        </template>
        <CListGroup class="list-group-accent">
          <CListGroupItem
            class="
              list-group-item-accent-secondary
              bg-light
              text-center
              font-weight-bold
              text-muted text-uppercase
              small
            "
          >
            <CButton
              size="sm"
              color="primary"
              block
              @click="
                $router.push('/newrole');
                $store.commit('toggle', 'asideShow');
              "
              >{{ $t("user.newrole.title") }}</CButton
            >
          </CListGroupItem>
        </CListGroup>
        <div class="input-group">
          <div class="input-group-prepend">
            <button type="button" class="btn btn-primary">
              <CIcon name="cil-magnifying-glass" />
            </button>
          </div>

          <input
            type="text"
            :placeholder="$t('aside.roles.search_placeholder')"
            class="form-control"
            v-model="search_term"
          />
          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="search_term.length == 0"
              @click="
                search_term = '';
                search_itmes = [];
              "
            >
              <CIcon name="cil-trash" />
            </button>
          </div>
        </div>
        <CListGroup class="list-group-accent">
          <CListGroupItem class="text-center" v-if="search_searching">
            <div class="sk-wave">
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
              <div class="sk-wave-rect"></div>
            </div>
          </CListGroupItem>
          <CListGroupItem
            class="
              list-group-item-accent-secondary
              bg-warning
              font-weight-bold
              text-uppercase
              small
              text-center
            "
            v-else-if="(search_itmes.length == 0) & (search_term.length > 0)"
          >
            {{ $t("aside.roles.search_notfound") }}
          </CListGroupItem>
        </CListGroup>
        <CListGroup class="list-group-accent">
          <CListGroupItem
            v-if="search_itmes.length > 0"
            class="
              list-group-item-accent-secondary
              bg-light
              text-center
              font-weight-bold
              text-muted text-uppercase
              small
            "
          >
            {{ $t("aside.roles.search_found") }}
          </CListGroupItem>
          <CListGroupItem
            v-for="role in search_itmes"
            :key="role.id"
            href="#"
            :class="[
              { 'list-group-item-accent-danger': role.r_type == 1 },
              { 'list-group-item-accent-success': role.r_type == 2 },
              { 'list-group-item-accent-success': role.r_type == 3 },
              { 'list-group-item-accent-info': role.r_type == 4 },
            ]"
            @click="GoToRole(role)"
          >
            <div class="c-avatar float-right">
              <img
                class="c-avatar-img"
                v-bind:src="role.image_cropped"
                :alt="'F' + role.id"
              />
            </div>
            <div>
              <strong>{{ role.name }}</strong
              ><br />{{ role.get_r_type_display }}
            </div>
            <small class="text-muted">
              <CIcon name="cil-phone" />&nbsp;&nbsp;{{ role.tel }}
            </small>
          </CListGroupItem>
        </CListGroup>

        <CListGroup
          class="list-group-accent"
          v-if="
            search_itmes.length == 0 &&
            items_lasts != null &&
            items_recent != null
          "
        >
          <CListGroupItem
            class="
              list-group-item-accent-secondary
              bg-light
              text-center
              font-weight-bold
              text-muted text-uppercase
              small
            "
          >
            {{ $t("aside.roles.recent.title") }}
          </CListGroupItem>
          <CListGroupItem
            v-for="role in items"
            :key="role.id"
            href="#"
            :class="[
              { 'list-group-item-accent-danger': role.r_type == 1 },
              { 'list-group-item-accent-success': role.r_type == 2 },
              { 'list-group-item-accent-success': role.r_type == 3 },
              { 'list-group-item-accent-info': role.r_type == 4 },
            ]"
            @click="GoToRole(role)"
          >
            <div class="c-avatar float-right">
              <img
                class="c-avatar-img"
                v-bind:src="role.image_cropped"
                :alt="'F' + role.id"
              />
            </div>
            <div>
              <strong>{{ role.name }}</strong
              ><br />{{ role.get_r_type_display }}
            </div>
            <small class="text-muted">
              <CIcon name="cil-phone" />&nbsp;&nbsp;{{ role.tel }}
            </small>
          </CListGroupItem>
        </CListGroup>
      </CTab>
      <CTab v-if="user.is_superuser || user.is_staff">
        <template slot="title">
          <CIcon name="cil-shield-alt" />
          {{ $t("aside.admin.title") }}</template
        >
        <CListGroup>
          <CListGroupItem
            class="
              list-group-item-accent-secondary
              bg-light
              text-center
              font-weight-bold
              text-muted text-uppercase
              small
            "
            v-if="user.is_staff || user.is_superuser"
          >
            {{ $t("aside.admin.title_long") }}
          </CListGroupItem>
          <CListGroupItem
            v-if="user.is_superuser"
            href="#"
            class="list-group-item-accent-secondary list-group-item-divider"
            to="/loonity"
          >
            <div class="c-avatar float-right">
              <img
                class="c-avatar-img"
                src="./../../public/loonity/brand/LoonityLogoBlackSyg.png"
              />
            </div>
            <div>
              <strong>Loonity</strong>
            </div>
          </CListGroupItem>
          <CListGroupItem
            v-if="user.is_staff"
            href="/admin"
            target="_blank"
            class="list-group-item-accent-secondary list-group-item-divider"
          >
            <div class="c-avatar float-right">
              <img
                class="c-avatar-img"
                src="./../../public/loonity/brand/LoonityLogoBlackSyg.png"
              />
            </div>
            <div><strong>Loonity</strong> Admin</div>
          </CListGroupItem>
        </CListGroup>
      </CTab>
      <CTab v-if="user.catcher_url">
        <template slot="title">
          <CIcon name="cil-shield-alt" /> {{ $t("aside.tools.title") }}
        </template>
        <CListGroup>
          <CListGroupItem
            class="
              list-group-item-accent-secondary
              bg-light
              text-center
              font-weight-bold
              text-muted text-uppercase
              small
            "
          >
            {{ $t("aside.tools.mailcatcher") }}
          </CListGroupItem>
          <CListGroupItem
            :href="user.catcher_url"
            target="_blank"
            class="list-group-item-accent-secondary list-group-item-divider"
          >
            <div class="c-avatar float-right">
              <img
                class="c-avatar-img"
                src="./../../public/loonity/brand/LoonityLogoBlackSyg.png"
              />
            </div>
            <div>
              <strong>{{ $t("aside.tools.mailcatcher_long") }}</strong>
            </div>
          </CListGroupItem>
        </CListGroup>
      </CTab>
    </CTabs>
  </CSidebar>
</template>

<script>
import { DataManager, Query, Predicate } from "@syncfusion/ej2-data";
import DrfAdaptor from "../http/EJ2_adapter";
import _ from "lodash";
import { apiUrl } from "../http";

const dm = new DataManager({
  url: apiUrl(`/api/auth/roles/`),
  adaptor: new DrfAdaptor(),
  crossDomain: true,
});
export default {
  name: "TheAside",
  data() {
    return {
      items_recent: null,
      items_lasts: [],
      search_itmes: [],
      search_term: "",
      search_searching: false,
    };
  },

  created() {
    // Fetch recent
    // this.GetRecents();
    // Fetch last
    this.GetLast();
    // Debounce Search
    this.debouncedFilterRoles = _.debounce(this.FilterRoles, 500);
  },
  watch: {
    // whenever question changes, this function will run
    search_term: function () {
      // this.answer = 'Waiting for you to stop typing...'
      this.debouncedFilterRoles();
    },
  },
  computed: {
    items() {
      if (this.items_lasts.length > 0 || this.items_recent.length > 0) {
        // console.log(this.items_recent);
        // console.log(this.items_lasts)
        let i_items_recent = this.items_recent.reduce(function (map, obj) {
          map.push(obj.id);
          return map;
        }, []);
        let f_items_lasts = this.items_lasts.filter((obj) => {
          return !i_items_recent.includes(obj.id);
        });
        return this.items_recent.concat(f_items_lasts);
      }
      return [];
    },
    user() {
      return this.$auth.user() || {};
    },
  },
  methods: {
    GetRecents() {
      let role_history = localStorage.getItem("role_history");
      if (role_history) {
        role_history = JSON.parse(role_history);
        // Build filter
        let predicate = new Predicate("id", "lessthan", 0);
        role_history.forEach(function (entry) {
          predicate = predicate.or("id", "equal", entry);
        });
        // get results
        let tmp_result = [];
        dm.executeQuery(new Query().where(predicate)).then((e) => {
          tmp_result = e.result;
          let tmp_result_o = [];
          role_history.forEach(function (entry) {
            let tmp_result_res = tmp_result.filter((obj) => {
              return obj.id == entry;
            })[0];

            if (tmp_result_res) {
              tmp_result_o.push(tmp_result_res);
            }
          });
          // console.log("TMP",tmp_result_o);
          this.items_recent = tmp_result_o;
        });
      } else {
        this.items_recent = [];
      }
    },
    GetLast() {
      dm.executeQuery(
        new Query().take(10).sortBy("ts_created", "descending")
      ).then((e) => {
        this.items_lasts = e.result;
        this.GetRecents();
      });
    },
    FilterRoles() {
      if (this.search_term) {
        this.search_searching = true;
        dm.executeQuery(
          new Query()
            .search(this.search_term, ["name", "id"], null, true)
            .take(10)
        ).then((e) => {
          this.search_searching = false;
          this.search_itmes = e.result;
        });
      }
    },
    GoToRole(role) {
      this.$store.commit("setRole", role);
      // console.log(this.$store.state.role.name)
      //save local history
      let role_history = localStorage.getItem("role_history");
      // console.log(role_history)
      if (!role_history) {
        role_history = [];
      } else {
        role_history = JSON.parse(role_history);
      }
      role_history = role_history.filter(function (value) {
        return value != role.id;
      });
      role_history.unshift(role.id);
      console.log(role_history);
      localStorage.setItem(
        "role_history",
        JSON.stringify(role_history.slice(0, 10))
      );
      this.items_recent = this.items_recent.filter(function (obj) {
        return obj.id != role.id;
      });
      this.items_recent.unshift(role);
      this.items_recent = this.items_recent.slice(0, 10);

      this.search_term = "";
      this.search_itmes = [];
      this.$router.push({ path: `/role/${role.id}` }).catch(() => {});
      this.$store.commit("set", ["asideShow", false]);
    },
    // roles () {
    //   roles = new DataManager({
    //     url: LOONITY_BASE_API_URL+"/api/auth/family/",
    //     adaptor: new DrfAdaptor(),
    //     crossDomain: true,
    //   }).fecth()
    //   return roles
    // },
  },
};
</script>
<style src="spinkit/spinkit.min.css"></style>
<style scoped>
div.sk-wave {
  margin: auto;
}
</style>
